import React, { Children, useEffect, useState } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import * as ProjectTemStyle from "../styles/ProjectTem.module.css";
import { Nav } from "../components/Nav";
import { Footer } from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Cursor } from "../components/Cursor";
import CursorContextProvider from "../components/CursorContextProvider";
import GifVideo from "../components/GifVideo";
import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform,
} from "framer-motion";
import { MDXRenderer } from "gatsby-plugin-mdx";

export const ProgressBar = () => {
  const [isComplete, setIsComplete] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  useEffect(() => yRange.onChange((v) => setIsComplete(v >= 1)), [yRange]);

  return (
    <>
      <svg
        className={ProjectTemStyle.progressBar}
        height="500px"
        width="500px"
        viewBox="0 0 100 100"
      >
        <motion.path
          fill="none"
          strokeWidth="1"
          stroke="#f41f54"
          strokeDasharray="0 1"
          d="M0 0 14 0A1 1 0 0114 11L0 11"
          style={{
            pathLength,
            translateX: 0,
            translateY: 18.5,
          }}
        />
      </svg>
    </>
  );
};

export default function ProjectTem({ data }) {
  const { body } = data.mdx;
  const { title_zh, title_en, stack, thumbType, year, description } =
    data.mdx.frontmatter;
  return (
    <CursorContextProvider>
      <Cursor />
      <title>{title_zh} - Manatee</title>
      <motion.div className={ProjectTemStyle.container}>
        <button onClick={() => navigate(-1)}>Go Back</button>
        <ProgressBar />
        <Nav />
        <motion.div className={ProjectTemStyle.introContainer}>
          <motion.div className={ProjectTemStyle.intro}>
            <motion.div
              layout="size"
              layoutId={data.mdx.frontmatter.thumb.id}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 25,
              }}
            >
              {thumbType === "image" ? (
                <GatsbyImage
                  className={ProjectTemStyle.introImage}
                  image={
                    data.mdx.frontmatter.thumb.childImageSharp.gatsbyImageData
                  }
                  alt="CARD"
                  width={520}
                  height={520}
                  decoding="async"
                  loading="lazy"
                  placeholder="blur"
                  overflow="hidden"
                  objectFit="cover"
                  quality={100}
                  transformOptions={{ fit: "cover", cropFocus: "attention" }}
                />
              ) : (
                <GifVideo
                  className={ProjectTemStyle.introVideo}
                  src={data.mdx.frontmatter.thumb.publicURL}
                />
              )}
            </motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.2, ease: [0.37, 0, 0.63, 1] },
            }}
            viewport={{ once: true }}
          >
            <div className={ProjectTemStyle.descriptionContainer}>
              <div className={ProjectTemStyle.grid}>
                <div className={ProjectTemStyle.information}>
                  <h3>
                    {year} - {stack}
                  </h3>
                </div>
                <div className={ProjectTemStyle.title}>
                  <h2 className={ProjectTemStyle.zh}>{title_zh}</h2>
                  <h2 className={ProjectTemStyle.en}>{title_en}</h2>
                </div>

                <p>{description}</p>
              </div>
            </div>
          </motion.div>
          <div className={ProjectTemStyle.projectContext}>
            <MDXRenderer localImages={data.mdx.frontmatter.embeddedImagesLocal}>
              {data.mdx.body}
            </MDXRenderer>
          </div>
        </motion.div>

        <Footer />
      </motion.div>
      <div></div>
    </CursorContextProvider>
  );
}

export const query = graphql`
  query ProjectsPage($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title_zh
        title_en
        stack
        year
        description
        thumbType
        thumb {
          id
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        embeddedImagesLocal {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
