// extracted by mini-css-extract-plugin
export var container = "ProjectTem-module--container--FIqgE";
export var img = "ProjectTem-module--img--QAWmI";
export var introContainer = "ProjectTem-module--introContainer--+vG9o";
export var intro = "ProjectTem-module--intro--4DI7B";
export var introImage = "ProjectTem-module--introImage--ytnwG";
export var introVideo = "ProjectTem-module--introVideo--pYQeM";
export var descriptionContainer = "ProjectTem-module--descriptionContainer--3igud";
export var title = "ProjectTem-module--title--GYZyy";
export var grid = "ProjectTem-module--grid--tgVZl";
export var information = "ProjectTem-module--information--Japf6";
export var zh = "ProjectTem-module--zh--ulVhP";
export var en = "ProjectTem-module--en--2NjJN";
export var projectContext = "ProjectTem-module--projectContext---M9Xq";
export var progressBar = "ProjectTem-module--progressBar--z8vPP";